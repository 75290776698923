import { render, staticRenderFns } from "./agreementOrdersAL.vue?vue&type=template&id=188185a2&scoped=true&lang=pug"
import script from "./agreementOrdersAL.vue?vue&type=script&lang=js"
export * from "./agreementOrdersAL.vue?vue&type=script&lang=js"
import style0 from "./agreementOrdersAL.vue?vue&type=style&index=0&id=188185a2&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "188185a2",
  null
  
)

export default component.exports